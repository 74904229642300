import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/image';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { CircleChevronRightFill, Briefcase, Truck } from 'akar-icons';

const IndexPage = () => {
    const strokeani = {
        duration: 1.65,
        ease: [0.19, 1.0, 0.22, 1.0],
        delay: 1,
    };

    return (
        <Layout>
            <Seo title="Business Equipment Finance" description="Placeholder" />
            <div className="app-hero">
                <div className="herotxt">
                    <div className="wrapper">
                        <div className="herotxt__box">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                transition={{
                                    duration: 0.45,
                                    delay: 0.85,
                                    ease: [0.23, 0.46, 0.4, 1],
                                }}
                                variants={{
                                    visible: { opacity: 1, y: 0 },
                                    hidden: { opacity: 0, y: -15 },
                                }}
                            >
                                <div>
                                    <h1>
                                        Business Equipment <span>Finance</span>
                                    </h1>
                                    <p>
                                        Our market position guarantees we get
                                        preferential interest rates on the full
                                        range of equipment finance facilities
                                        from every major bank and specialised
                                        lender in the market.
                                    </p>
                                    <Link to="/quote" className="btn">
                                        Get a Quote{' '}
                                        <CircleChevronRightFill
                                            strokeWidth={2}
                                            size={35}
                                        />
                                    </Link>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                    <div className="herostroke">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1983.911 495.33"
                        >
                            <motion.path
                                d="M-12,478.667S407.573,762,897.544,838.566c354.084,55.329,745.982,1.428,1039.713-350.388"
                                transform="translate(-4.313 -367.861) rotate(-3)"
                                fill="none"
                                stroke="#c2d83d"
                                strokeLinecap="round"
                                strokeWidth="12"
                                initial={{ pathLength: 0 }}
                                animate={{ pathLength: 1 }}
                                transition={strokeani}
                            />
                        </svg>
                        <motion.div
                            initial={{ offsetDistance: '0%', scale: 2.5 }}
                            animate={{ offsetDistance: '100%', scale: 1 }}
                            transition={strokeani}
                        />
                    </div>
                </div>
                <div className="herotxtbg">
                    <Image src="banner-bg.svg" alt="" />
                </div>
                <div className="heroimg">
                    <Image src="heroimg.png" alt="" />
                </div>
            </div>
            <div className="app-ctas">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="ctas">
                            <div className="ctas__title">
                                <h1>
                                    Our <span>Offerings</span>
                                </h1>
                            </div>
                            <div className="ctas__item">
                                <Link to="/dealers" className="ctalink">
                                    <div className="ctalink__txt">
                                        <div>
                                            <span>Business</span>{' '}
                                            <span>Financing</span>
                                        </div>
                                        <Briefcase strokeWidth={1} size={66} />
                                    </div>
                                    <small>
                                        <CircleChevronRightFill
                                            strokeWidth={2}
                                            size={35}
                                        />
                                    </small>
                                </Link>
                            </div>
                            <div className="ctas__item">
                                <Link
                                    to="/equipment-finance"
                                    className="ctalink"
                                >
                                    <div className="ctalink__txt">
                                        <div>
                                            <span>Equipment</span>{' '}
                                            <span>Financing</span>
                                        </div>
                                        <Truck strokeWidth={1} size={66} />
                                    </div>
                                    <small>
                                        <CircleChevronRightFill
                                            strokeWidth={2}
                                            size={35}
                                        />
                                    </small>
                                </Link>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="app-intro">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="intro">
                            <div className="intro__left">
                                <h1>
                                    Secure Your <span>Business Growth</span>
                                </h1>
                            </div>
                            <div className="intro__right">
                                <p>
                                    Our market position guarantees we get
                                    preferential interest rates on the full
                                    range of equipment finance facilities from
                                    every major bank and specialised lender in
                                    the market.
                                </p>
                                <p>
                                    We take time to understand your business’s
                                    strengths and weaknesses, providing you and
                                    your bankers with a measured financial
                                    approach, ensuring a positive outcome.
                                </p>
                                <Link to="/quote" className="btn">
                                    Get a Quote{' '}
                                    <CircleChevronRightFill
                                        strokeWidth={2}
                                        size={35}
                                    />
                                </Link>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="app-testi">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="testi">
                            <div className="testi__item">
                                <div>
                                    <h2>
                                        2022{' '}
                                        <span>
                                            <em>Assets</em> Funded
                                        </span>
                                    </h2>
                                    <p>
                                        Include new and used excavators,
                                        loaders, dump trucks, LVs, prime movers,
                                        drilling rigs, graders and cranes.
                                    </p>
                                </div>
                            </div>
                            <Image
                                src="qu-perth.png"
                                alt="Quokka Finance Testimonial"
                            />
                        </div>
                    </motion.div>
                </div>
            </div>
        </Layout>
    );
};

export default IndexPage;
